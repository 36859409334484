



































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  setup(_, { root }) {
    const state = reactive({
      loading: false,
      loaded: false,
      items: [],
      headers: [
        { value: "lastName", text: root.$tc("layout.misc.lastName") },
        {
          value: "firstName",
          text: root.$tc("layout.misc.firstName"),
          sortable: false,
        },
        {
          value: "date",
          text: root.$tc("panel.event.accessControl.presence.date"),
        },
      ],
      total: 0,
      controlPoint: {},
    });

    const fetch = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`presence/control-point/${root.$route.params.said}/presence`)
        .then(({ data: { presences, total } }) => {
          state.items = presences;
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetch);

    const fetchSingleControlPoint = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`control-point/${root.$route.params.said}`)
        .then(({ data: { controlPointRow } }) => {
          state.controlPoint = controlPointRow;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.controlPoint = {};
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchSingleControlPoint);

    return { state };
  },
});
